<template>
  <div class="flex justify_center align_center flex_column">
    <div class="image">
      <img src="@/assets/image/completed.png" alt="完成图片" />
    </div>
    <div class="font_16">操作完成</div>
  </div>
</template>

<script>
export default {
  name: "formComplete",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.image {
  width: 60%;
  margin-top: 86px;
  img{
      width:100%;
    height: 100%;
  }
}
// .image img {
//     width:100%;
//     height: 100%;
// }
</style>